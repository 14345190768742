export const config = {
  endpoints: {
    notification: "/notificationhub",
    api: "/api",
    api_version: "v1",
  },
  timers: {
    keepAlive: 10000, //ms
    fetchDataPolling: 10000,
  },
};
