import {
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Box,
  Button,
  Tooltip,
  Switch,
} from "@chakra-ui/react";
import { format, isMatch, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SliderPopup from "components/slider/SliderPopup";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";

//per DeviceTableContent e OperatorsTableContent
const maxTagsLength = 20;
const colorMap = {
  device_list: ["#7ce6b1", "pcr.100", "#f47b71"],
  events_list: ["#E0F7FA", "#64a8f3", "pcr.100", "#f47b71", "#7ce6b1"],
  operators_list: ["white", "#7ce6b1", "pcr.100", "#f47b71", "lightgray"],
  logs_list: ["white", "pcr.100", "#7ce6b1", "pcr.200", "#f47b71"],
};

//per EventsTableContent
const bgColor = "pcr.200";
const hoverColor = "pcr.100";

const isValidDate = (dateString) => {
  const str = String(dateString);
  const correctedDateString = str.replace(/\.\d{3,}/, (match) =>
    match.substring(0, 4)
  );
  const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?)?$/;

  if (!datePattern.test(correctedDateString)) {
    return false;
  }

  const timestamp = new Date(dateString);
  return !isNaN(timestamp.getTime());
};

const isNumber = (str) => !isNaN(str) && !isNaN(parseFloat(str));
const isBoolean = (str) => str === "boolean";

function getDataTypes(value) {
  let type;
  if (value === null) {
    type = "null";
  } else if (isBoolean(typeof value)) {
    type = "enabled";
  } else if (Array.isArray(value)) {
    type = "textList";
  } else if (isNumber(value)) {
    type = "text";
  } else if (isValidDate(value)) {
    type = "date";
  } else {
    type = value || "text";
  }

  return type;
}

export const formatDate = (dateString, pageType = null) => {
  if (dateString == null || dateString === "-") {
    return "-";
  }
  if (
    isMatch(dateString, "dd/MM/yyyy HH:mm") ||
    isMatch(dateString, "dd/MM/yyyy HH:mm:ss.SSS")
  ) {
    return dateString;
  }

  const date = new Date(dateString);
  if (isNaN(date)) {
    console.error("Invalid date format: " + dateString);
  }

  return format(date, "dd/MM/yyyy HH:mm:ss.SSS");
};

const TableContent = ({
  page,
  prepareRow,
  textColor,
  columnsList,
  pageType,
  toggleStates,
  onToggleChange,
  rowClick,
  selectOptions,
  onOptionChange,
  statusOptions,
}) => {
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [openPopupState, setOpenPopupState] = useState({
    isOpen: false,
    component: null,
  });

  const navigate = useNavigate();

  const handleOpen = (row, component) => {
    setSelectedRow(row);
    setOpenPopupState({
      isOpen: true,
      component,
    });
  };

  const handleClose = () => {
    setOpenPopupState({
      isOpen: false,
      component: null,
    });
    setSelectedRow(null);
  };

  const getStringedItemList = (items, sep = ", ", maxChars = maxTagsLength) => {
    let itemList = items && items.length > 0 ? items.join(sep) : null;
    if (itemList?.length > maxChars) {
      let truncatedString = itemList.substring(0, maxChars);
      const lastCommaIndex = truncatedString.lastIndexOf(sep);
      itemList =
        lastCommaIndex > 0
          ? truncatedString.substring(0, lastCommaIndex) + " ..."
          : truncatedString + " ...";
    }
    return itemList;
  };

  const buildTextList = (
    cell,
    textColor,
    mainName = "name",
    tooltipName = null,
    sep = ", "
  ) => {
    let arrayValue = [];
    let tooltipValue = [];
    if (Array.isArray(cell.value)) {
      arrayValue = cell.value.map((item) => {
        return typeof item === "object"
          ? item[mainName] || JSON.stringify(item)
          : item;
      });
    } else if (typeof cell.value === "string") {
      arrayValue = cell.value.split(",").map((tag) => tag.trim());
    } else {
      arrayValue = [cell.value];
    }

    tooltipValue = [...arrayValue];

    if (tooltipName && Array.isArray(cell.value)) {
      tooltipValue = cell.value.map((item) => {
        return typeof item === "object"
          ? item[tooltipName] || JSON.stringify(item)
          : item;
      });
    }

    return (
      <Tooltip label={getStringedItemList(tooltipValue, sep, 100)}>
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {getStringedItemList(arrayValue, sep, 1000) || "-"}
        </Text>
      </Tooltip>
    );
  };

  const renderers = {
    bool: (cell, textColor) => {
      return (
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {cell.value ? "true" : "false"}
        </Text>
      );
    },
    date: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {formatDate(cell.value, pageType) || "-"}
      </Text>
    ),
    text: (cell, textColor) => {
      return (
        <Tooltip label={cell.value}>
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {cell.value || "-"}
          </Text>
        </Tooltip>
      );
    },
    textTooltip: (cell, textColor) => {
      return (
        <Tooltip label={cell.value.tooltip}>
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {cell.value.main || "-"}
          </Text>
        </Tooltip>
      );
    },
    textIcon: (cell, textColor) => {
      return (
        <Box display={"flex"}>
          <Tooltip label={cell.value}>
            <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
              {cell.value || "-"}
            </Text>
          </Tooltip>
          <Icon fontSize={"large"}>{cell?.column?.icon}</Icon>
        </Box>
      );
    },
    textList: (cell, textColor) => {
      return buildTextList(cell, textColor);
    },
    textListCustom: (cell, textColor) => {
      if (
        cell.value.renderInfo?.main &&
        cell.value.renderInfo?.tooltip &&
        cell.value.value
      ) {
        return buildTextList(
          cell.value,
          textColor,
          cell.value.renderInfo.main,
          cell.value.renderInfo.tooltip,
          " > "
        );
      } else {
        return buildTextList(cell, textColor);
      }
    },
    status: (cell, textColor) => {
      var options = statusOptions?.find((s) => s.key === cell?.column?.id);
      var value =
        ![null, undefined].includes(cell.value) &&
        ![null, undefined].includes(options) &&
        ![null, undefined].includes(options.values)
          ? options.values.find((v) => v.value === cell.value)?.label
          : "-";

      return (
        <>
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {value}
          </Text>
        </>
      );
    },
    enabled: (cell, textColor) =>
      selectOptions ? (
        <div onClick={(e) => e.stopPropagation()}>
          <ReactSelect
            menuPlacement="bottom"
            options={selectOptions}
            onChange={(selectedOption) => {
              onOptionChange(cell.row.original, selectedOption);
            }}
            value={
              selectOptions.find((option) => option.value === cell.value) ||
              null
            }
            placeholder={t(`${pageType}.select_severity`)}
            styles={{
              control: (base) => ({
                ...base,
                minWidth: "150px",
                maxWidth: "200px",
                backgroundColor: "#EEEEEE",
                borderColor: "black",
                fontSize: "1.2em",
              }),
              menu: (base) => ({ ...base, fontSize: "1.2em" }),
            }}
          />
        </div>
      ) : (
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {cell.value + ""} {/*? t(`operators_list.enabled`) : "-"} */}
        </Text>
      ),
    type: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {t(`${pageType}.types.${cell.value}`) || "-"}
      </Text>
    ),
  };

  const defaultRenderer = (cell, textColor) => (
    <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
      {cell.value || "-"}
    </Text>
  );

  const formatValue = (cell) => {
    let type = getDataTypes(cell?.column?.type);
    const renderer = renderers[type] || defaultRenderer;
    return renderer(cell, textColor);
  };

  const renderStatusColor = (row) => {
    const statusValue = row.allCells.find(
      (cell) => getDataTypes(cell?.column?.type) === "status"
    )?.value;

    if (statusValue !== undefined && statusValue !== null) {
      return colorMap[pageType][statusValue];
    }
    return "white";
  };

  const formatColumnsValue = (columnList, row) => {
    switch (columnList.accessor) {
      case "button":
        return columnList.customButton ? (
          columnList.customButton(row)
        ) : (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            // ml={"5px"}
            textColor="black"
            onClick={(e) => {
              handleOpen(row, columnList.sliderPopupComponent);
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "buttonOptional":
        return columnList.condition(row) ? (
          columnList.customButton ? (
            columnList.customButton(row)
          ) : (
            <Button
              key={row.index}
              bgColor={bgColor}
              _hover={{ bgColor: hoverColor }}
              // ml={"5px"}
              textColor="black"
              onClick={(e) => {
                handleOpen(row, columnList.sliderPopupComponent);
                e.stopPropagation();
              }}
            >
              {t(columnList.Header)}
            </Button>
          )
        ) : (
          <></>
        );
      case "redirectButton":
        return (
          <Button
            key={row.index}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
            textColor="black"
            onClick={(e) => {
              navigate(columnList.path, { state: { id: row.original.id } });
              e.stopPropagation();
            }}
          >
            {t(columnList.Header)}
          </Button>
        );
      case "toggle":
        return (
          <Flex direction="column">
            <Flex pt="5px">
              <Switch
                isChecked={toggleStates[row.original.kName] || false}
                onChange={(e) =>
                  onToggleChange(row.original.kName, e.target.checked)
                }
              />
            </Flex>
          </Flex>
        );
      default:
        return <span>{columnList.Header}</span>;
    }
  };

  return (
    <>
      <Tbody>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Tr
              {...row.getRowProps()}
              key={row.id}
              bgColor={renderStatusColor(row)}
              transition={"background-color 0.2s, box-shadow 0.2s"}
              _hover={{
                bgColor: rowClick ? "lightblue" : "",
                boxShadow: rowClick ? "0px 4px 8px rgba(0, 0, 0, 0.2)" : "",
                border: rowClick ? "1px solid blue" : "",
              }}
              onClick={() => {
                if (rowClick) {
                  handleOpen(row, rowClick.render);
                }
              }}
              cursor={rowClick ? "pointer" : "auto"}
            >
              {row.cells.map((cell) => {
                let data = <Flex align="center">{formatValue(cell)}</Flex>;
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={cell.id}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    {data}
                  </Td>
                );
              })}

              {columnsList.map((columList, index) => {
                if (columList.page === pageType) {
                  return (
                    <Td
                      key={columList.Header + index}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {formatColumnsValue(columList, row)}
                    </Td>
                  );
                }
                return null;
              })}
            </Tr>
          );
        })}
      </Tbody>
      {openPopupState.isOpen && openPopupState.component && (
        <SliderPopup isOpen={openPopupState.isOpen} onClose={handleClose}>
          {openPopupState.component(handleClose, selectedRow)}{" "}
        </SliderPopup>
      )}
    </>
  );
};

export default TableContent;
