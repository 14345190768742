/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEventsStore } from "contexts/stores";

// Chakra imports
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import MobileEventsTable from "./components/MobileEventsTable";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import EventManage from "./components/EventHistory/EventManage";
import { columnsData } from "./variables/columnsData";
import { useToggle } from "components/externalComponents/ToggleContext";
import MeasuresTable from "views/measures";
import { addMilliseconds, format, parse, subMilliseconds } from "date-fns";

const EventsPage = ({ applyFilter, isInDrawer = false }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();
  const [filteredEvents, setFilteredEvents] = useState({});

  const statusOptions = [
    {
      key: "status",
      values: [
        { value: 0, label: t(`events_list.status_list.0`) },
        { value: 1, label: t("events_list.status_list.1") },
        { value: 2, label: t("events_list.status_list.2") },
        { value: 3, label: t("events_list.status_list.3") },
        { value: 4, label: t("events_list.status_list.4") },
      ],
    },
  ];

  const columnsList = [
    {
      Header: "raw_data_link",
      accessor: "button",
      page: "events_list",
      sliderPopupComponent: (handleClose, row) => {
        return (
          <MeasuresTable
            applyFilter={[
              {
                column: "eventId",
                value: row?.original?.id,
              }
            ]}
            isInDrawer={true}
          />
        );
      },
    },
  ];

  const rowClick = {
    page: "device_list",
    render: (handleClose, row) => (
      <EventManage onClose={handleClose} eventID={row?.original?.id} />
    ),
  };

  const { events, isUpdating, fetchEvents, setIsInDrawer, isInDrawerStore } =
    useEventsStore((state) => ({
      events: state.events,
      isUpdating: state.isUpdating,
      fetchEvents: state.fetchEvents,
      setIsInDrawer: state.setIsInDrawer,
      isInDrawerStore: state.isInDrawer,
    }));

  const { toggleStates } = useToggle(); // Accedi ai toggle

  useEffect(() => {
    //if I'm in the drawer I also update the devices if I detect a new fetchEvents (not the one inside this useEffect)
    if (!isInDrawer) {
      setIsInDrawer(isInDrawer);
    }
    if (isInDrawer && isInDrawerStore) {
      setIsInDrawer(false);
    }
    fetchEvents();
    if (isInDrawer) setIsInDrawer(isInDrawer);
  }, [fetchEvents]);

  const toggleObject = typeof toggleStates === "object" ? toggleStates : {};

  useEffect(() => {
    // Filtra gli eventi in base ai toggle
    setFilteredEvents(
      events
        ?.filter((event) => {
          if (event.variable in toggleObject) {
            return toggleObject[event.variable] === false;
          }
          return true;
        })
        .map((e) => {
          return {
            ...e,
            deviceRoots: {
              value: e.deviceRoots,
              renderInfo: { main: "name", tooltip: "type" },
            },
          };
        })
    );
  }, [events, toggleObject]);

  return (!filteredEvents || filteredEvents.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      {filteredEvents !== undefined &&
      filteredEvents !== null &&
      filteredEvents?.length > 0 ? (
        isMobile ? (
          <MobileEventsTable data={filteredEvents} />
        ) : (
          <FiltersTable
            columnsList={columnsList}
            columnsVariables={columnsData}
            navigateToPage={isInDrawer ? "/events" : ""}
            tableData={filteredEvents}
            rowClick={rowClick}
            pageType={"events_list"}
            statusOptions={statusOptions}
            hideColumnsOnLoad={{
              id: false,
              eventTime: true,
              deviceRoots: true,
              location: true,
              status: true,
              description: true,
              variable: false,
              variableDescription: true,
              variableLevel: true,
              counter: true,
            }}
            applyFilter={applyFilter ?? ""}
            isInDrawer={isInDrawer}
            isVisible={!isInDrawer ?? true}
          />
        )
      ) : isInDrawer ? (
        <>
          <FiltersTable
            columnsList={columnsList}
            columnsVariables={columnsData}
            navigateToPage={isInDrawer ? "/events" : ""}
            tableData={[]}
            rowClick={rowClick}
            pageType={"events_list"}
            statusOptions={statusOptions}
            hideColumnsOnLoad={{
              id: false,
              eventTime: true,
              deviceId: true,
              deviceType: false,
              location: true,
              status: true,
              description: true,
              variable: true,
              variableDescription: false,
              variableLevel: true,
              counter: true,
            }}
            applyFilter={applyFilter ?? ""}
            isInDrawer={isInDrawer}
            isVisible={!isInDrawer ?? true}
          />
        </>
      ) : (
        <>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default EventsPage;
