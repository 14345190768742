import React from "react";
import { Button, Flex, IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AddIcon } from "@chakra-ui/icons";

const FilterActions = ({ handleAddFilter }) => {
  const { t } = useTranslation();
  return (
    <Flex minWidth={"200px"} justifyContent={"center"}>
      <IconButton
        width={"160px"}
        height={"25px"}
        bgColor={"pcr.200"}
        _hover={{ bgColor: "pcr.100" }}
        onClick={handleAddFilter}
        icon={<AddIcon fontSize={10}/>}
        aria-label={t("column_filter.add")}
      />
    </Flex>
  );
};

export default FilterActions;
