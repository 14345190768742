import React, {useEffect} from "react";
import {
  Box,
  Flex,
  IconButton,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import Content from "components/sidebar/components/Content";
import logo from "assets/img/logo.png";
import { useInfoStore } from "contexts/stores";

const SidebarStandard = ({ routes, toggleSidebar, isOpen, sidebarWidth }) => {
  const sidebarBg = useColorModeValue("white", "gray.800");
  const {info, getServerInfo} = useInfoStore();

  useEffect(() => {
    getServerInfo();
  }, [getServerInfo])

  useEffect(() => {
    console.log(JSON.stringify(info));
  }, [info])

  return (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      h="100vh"
      w={isOpen ? sidebarWidth : "75px"}
      bg={sidebarBg}
      boxShadow="md"
      transition="width 0.3s"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <VStack>
        <Flex
          alignItems="center"
          justifyContent={isOpen ? "space-between" : "center"}
          p="4"
        >
          {isOpen && (
            <Image src={logo} alt="logo" width="180px" paddingRight="5px" />
          )}
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Toggle Sidebar"
            onClick={toggleSidebar}
            variant="ghost"
            _hover={{ bgColor: "pcr.100" }}
          />
        </Flex>

        <VStack
          alignItems={isOpen ? "flex-start" : "center"}
          spacing={4}
          mt="4"
        >
          <Content
            routes={routes}
            isOpen={isOpen}
            sidebarWidth={sidebarWidth}
          />
        </VStack>
      </VStack>
      {isOpen && (
        <Flex justifyContent="center" p="4">
          <Text fontSize="sm" color="gray.500">
            v{info?.version}
          </Text>
        </Flex>
      )}
    </Box>
  );
};

export default SidebarStandard;
