export const columnsRawData = [
  {
    Header: "timestamp",
    accessor: "timestamp",
    type: "date",
  },
  {
    Header: "rootType",
    accessor: "rootType",
    type: "text",
  },
  {
    Header: "deviceRoots",
    accessor: "deviceRoots",
    type: "textTooltip",
  },
  {
    Header: "device",
    accessor: "device",
    type: "text",
  },
  {
    Header: "variable",
    accessor: "variable",
    type: "text",
  },
  {
    Header: "variableDescription",
    accessor: "variableDescription",
    type: "text",
  },
  {
    Header: "value",
    accessor: "value",
  },
  {
    Header: "type",
    accessor: "type",
    type: "type",
  },
  {
    Header: "event",
    accessor: "event",
    type: "text"
  },
];
