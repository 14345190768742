import { Flex, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TriangleUpIcon, TriangleDownIcon } from "@chakra-ui/icons";

const TableHeader = ({
  headerGroups,
  isResizing,
  setIsResizing,
  isResizingRef,
  columnsList,
  pageType,
}) => {
  const { t } = useTranslation();
  return (
    <Thead cursor={isResizing ? "grabbing" : "col-resize"}>
      {headerGroups.map((headerGroup) => (
        <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
          {headerGroup.headers.map((column) => (
            <Th
              {...column.getHeaderProps(column.getSortByToggleProps())}
              pe="10px"
              key={column.id}
              borderColor="gray.300"
              style={{
                position: "relative",
                width: column.width,
              }}
              onClick={(e) => {
                if (isResizing || isResizingRef.current) {
                  e.stopPropagation();
                  return;
                }
                column.toggleSortBy();
              }}
              cursor={isResizing ? "grabbing" : "pointer"}
            >
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "12px", lg: "14px" }}
                color="gray.400"
                style={{
                  display: "inline-flex",
                  width: "calc(100% - 1px)",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Flex alignItems="center">
                  {pageType === undefined
                    ? t(`${column.Header}`)
                    : t(`${pageType}.${column.Header}`)}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <TriangleDownIcon aria-label="sorted descending" ml={1} />
                    ) : (
                      <TriangleUpIcon aria-label="sorted ascending" ml={1} />
                    )
                  ) : null}
                </Flex>

                {/* Resizer per ridimensionamento */}
                {column !== headerGroup.headers[headerGroup.headers.length - 1] && (
                  <div
                    onPointerDown={(e) => {
                      e.stopPropagation();
                      setIsResizing(true);
                      isResizingRef.current = true;

                      const handleMouseUp = (e) => {
                        e.stopPropagation();
                        setTimeout(() => {
                          isResizingRef.current = false;
                          setIsResizing(false);
                          document.removeEventListener(
                            "mouseup",
                            handleMouseUp
                          );
                        }, 200);
                      };

                      document.addEventListener("mouseup", handleMouseUp);
                    }}
                    {...column.getResizerProps()}
                    style={{
                      cursor: isResizing ? "grabbing" : "col-resize",
                      width: "1px",
                      height: "100%",
                      background: "gray",
                      position: "absolute",
                      top: 0,
                      right: 0,
                      zIndex: 1,
                      userSelect: "none",
                    }}
                  />
                )}
              </Flex>
            </Th>
          ))}

          {/* Colonne personalizzate */}
          {columnsList.map((columList, index) => (
            <Th key={index}>
              <Flex
                justify="space-between"
                align="center"
                fontSize={{ sm: "12px", lg: "14px" }}
                color="gray.400"
              >
                {t(columList.Header)}
              </Flex>
            </Th>
          ))}
        </Tr>
      ))}
    </Thead>
  );
};

export default TableHeader;
