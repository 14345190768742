import React, { useState } from "react";
import { Box, Button, Grid, Text, Textarea } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import API from "api/API";
import { useSnackbar } from "notistack";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";

const defaultNotifyNumber = 4;

function NotifyOperator({ operatorData, onClose }) {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const sendNotification = (value) => {
    API.sendNotification({
      title: "Notifica",
      body: value,
      topic: operatorData.id,
    })
      .then((result) => {
        if (result.isSuccess) {
          setText("");
          enqueueSnackbar(t("notify_operator.notification_success"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("notify_operator.notification_error"), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error sending notification:", error);
        enqueueSnackbar(t("notify_operator.notification_error"), {
          variant: "error",
        });
      });
  };

  return (
    <Box p={4}>
      <Text p={4} pb={"10px"} textAlign="center" fontSize={"xl"}>
        {t("notify_operator.notification_title")}{" "}
        {operatorData.firstName + " " + operatorData.lastName}
      </Text>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={4}
        m={"20px"}
        justifyContent="center"
        alignItems="center"
      >
        {Array.from({ length: defaultNotifyNumber }, (value, i) => i).map(
          (number) => (
            <Button
              key={number}
              bgColor={bgColor}
              _hover={{ bgColor: hoverColor }}
              onClick={() => {
                sendNotification(
                  t(`notify_operator.default_notification_text.${number}`)
                );
              }}
            >
              {t(`notify_operator.default_notification_title.${number}`)}
            </Button>
          )
        )}
      </Grid>
      <Box>
        <Textarea
          placeholder={t("notify_operator.custom_description")}
          onChange={(e) => {
            setText(e.target.value);
          }}
          minHeight={"150px"}
        />
        <Button
          mt={"20px"}
          width={"100%"}
          bgColor={bgColor}
          _hover={{ bgColor: hoverColor }}
          onClick={() => {
            sendNotification(text);
          }}
        >
          {t("notify_operator.custom_title")}
        </Button>
      </Box>
    </Box>
  );
}

export default NotifyOperator;
