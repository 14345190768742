import { useVariableStore } from "contexts/stores";

// Chakra imports
import {
  Box,
  Flex,
  Text,
  useBreakpointValue,
  Button,
  Collapse,
} from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";
import FiltersTable from "components/filtersTable/FiltersTable";
import { useToggle } from "components/externalComponents/ToggleContext";
import { formatDate } from "components/filtersTable/TableContent";
import API from "api/API";
import { enqueueSnackbar } from "notistack";
import UpdateVariableLayout from "./components/UpdateSeverityLayout";
import { useKeycloak } from "contexts/stores";
import { useOperatorsStore } from "contexts/stores";
import LogViewer from "./components/LogsViewer";

export default function SettingsPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { variable, isUpdating, fetchVariable } = useVariableStore();
  const { t } = useTranslation();
  const bgColor = "pcr.200";
  const hoverColor = "pcr.100";

  const [showTable, setShowTable] = useState(false); // Stato per gestire la visibilità della tabella
  const handleToggle = () => setShowTable(!showTable); // Funzione per alternare visibilità
  const { keycloak } = useKeycloak();
  const { operators, fetchOperators } = useOperatorsStore();
  const [authLogs, setAuthLogs] = useState(false);
  const [showLogsTable, setShowLogsTable] = useState(false);

  const columnsList = [
    {
      Header: "details",
      accessor: "button",
      page: "variable_list",
      sliderPopupComponent: (handleClose, row) => (
        <UpdateVariableLayout
          onClose={handleClose}
          variableId={row?.original?.id}
          variableData={row?.original}
        />
      ),
    },
  ];

  const handleExport = () => {
    function convertToCSV(data) {
      const headers = Object.keys(data[0]).map((name) => {
        return t(`settings.login_history_headers.${name}`);
      });

      const csvRows = [headers.join(",")];

      data.forEach((event) => {
        const row = Object.keys(data[0]).map((field) => {
          if (field === "time") {
            return formatDate(event[field]);
          }
          return event[field];
        });
        csvRows.push(row.join(","));
      });

      return csvRows.join("\n");
    }

    function exportToCSV(csvData, pageType) {
      const currentDate = new Date();

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hour = String(currentDate.getHours()).padStart(2, "0");
      const minute = String(currentDate.getMinutes()).padStart(2, "0");

      const formattedDate = `${year}_${month}_${day} ${hour}.${minute}`;

      var filename =
        formattedDate + "_" + t(`export_name.${pageType}`) + ".csv";

      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);
      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    }

    API.getLoginHistory().then((result) => {
      if (result.isSuccess) {
        var data = convertToCSV(result.data);
        exportToCSV(data, "settings_login");
      } else {
        enqueueSnackbar(t("settings.login_api_error"), {
          variant: "error",
        });
      }
    });
  };

  useEffect(() => {
    fetchVariable();
    fetchOperators();
  }, [fetchVariable]);

  useEffect(() => {
    if (operators)
      if (operators.find((o) => o.id === keycloak.subject && o?.logs)) {
        setAuthLogs(true);
      }
  }, [operators]);

  const { toggleStates, handleToggleChange } = useToggle(); // Accedi ai toggle

  return (!variable || variable.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Button
        onClick={handleToggle}
        bgColor={bgColor}
        _hover={{ bgColor: hoverColor }}
        ml={"5px"}
      >
        {showTable
          ? t("variable_list.hide_variableTable")
          : t("variable_list.show_variableTable")}{" "}
      </Button>

      <Button
        onClick={handleExport}
        bgColor={bgColor}
        _hover={{ bgColor: hoverColor }}
        ml={"5px"}
      >
        {t("settings.export_login_history")}{" "}
      </Button>

      {authLogs && (
        <Button
          onClick={() => {
            setShowLogsTable(!showLogsTable);
          }}
          bgColor={bgColor}
          _hover={{ bgColor: hoverColor }}
          ml={"5px"}
        >
          {t("settings.view_logs")}{" "}
        </Button>
      )}

      <Collapse in={showTable} animateOpacity>
        <Box
          border="2px solid"
          borderColor="gray.300"
          borderRadius="md"
          p="1rem"
          mt="1rem"
        >
          {variable !== undefined && variable !== null ? (
            <FiltersTable
              columnsList={columnsList}
              tableData={variable}
              pageType={"variable_list"}
              toggleStates={toggleStates}
              onToggleChange={handleToggleChange} // Passiamo la funzione per gestire i toggle
              hideColumnsOnLoad={{
                id: false,
                level: true,
                kName: true,
                kDescription: true,
                type: true,
                isError: true,
              }}
            />
          ) : (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              w={"100%"}
              minH={"100vh"}
            >
              <Flex alignItems={"center"}>
                <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                  {t("empty_data")}
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </Collapse>

      <Collapse in={showLogsTable} animateOpacity>
        {authLogs && <LogViewer />}
      </Collapse>
    </Box>
  );
}
