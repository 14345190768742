import React from "react";

export const FilterType = {
  ENABLED: "enabled",
  STATUS: "status",
  DATE: "date",
  BOOL: "bool",
  ID: "id",
};

export const RenderType = {};
